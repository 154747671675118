export const brand = {
  colors: {
    primary: "#253543",
    secondary: "#eb7748",
    text: "#ffffff",
    textDarker: "#f3f3f3",
    contrast: "#ffffff",
  },

  fonts: {
    normal: 22,
  },
}
