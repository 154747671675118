import { createUseStyles } from "react-jss"
import { brand } from "../../../../styles/brand"

const styles = createUseStyles({
  label: {
    fontSize: brand.fonts.normal,
    marginBottom: 10,
  },
})

export default styles
